import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store/index";
import { Message } from "element-ui";

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

// 解决报
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/", //登录
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: false,
    },
  },
  {
    path: "/doctorManage", //医生管理
    name: "DoctorManage",
    component: () => import("../views/DoctorManage.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/doctorManage/doctorDetail", //医生管理详情
    name: "DoctorDetail",
    component: () => import("../views/DoctorDetail.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/setDoctor", //医生的好转病例医生与患者绑定等操作
    name: "setDoctor",
    component: () => import("../views/setDoctor.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/setDoctor/addCase", //好转病例列表
    name: "addCase",
    component: () => import("../views/addCase.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/setDoctor/addCase/addCaseDetail", //好转病例详情
    name: "addCaseDetail",
    component: () => import("../views/addCaseDetail.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/articleManage", //文章管理
    name: "ArticleManage",
    component: () => import("../views/ArticleManage.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/hDManage", //科室管理
    name: "HDManage",
    component: () => import("../views/HDManage.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/labelManagement", //标签管理
    name: "labelManagement",
    component: () => import("../views/labelManagement.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/guidanceManage", //导诊管理
    name: "GuidanceManage",
    component: () => import("../views/GuidanceManage.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/articleManage/articleDetail", //文章管理详情
    name: "ArticleDetail",
    component: () => import("../views/ArticleDetail.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/selectRecommend", //精选推荐
    name: "selectRecommend",
    component: () => import("../views/selectRecommend.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/zzManage", //部位管理详情
    name: "ZzManage",
    component: () => import("../views/ZzManage.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/pictureManage", //轮播图管理
    name: "PictureManage",
    component: () => import("../views/PictureManage.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/illnessManage", //疾病管理
    name: "illnessManage",
    component: () => import("../views/illnessManage.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/illnessManage/illnessPics", //疾病轮播图管理
    name: "illnessPics",
    component: () => import("../views/illnessPics.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/illnessManage/illAddDoc", //疾病添加对应医生
    name: "illAddDoc",
    component: () => import("../views/illAddDoc.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/illnessManage/illAddCust", //疾病添加患者
    name: "illAddCust",
    component: () => import("../views/illAddCust.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/illnessManage/illAddCase/illAddCaseDetail", //疾病添加好转病例详情
    name: "illAddCaseDetail",
    component: () => import("../views/illAddCaseDetail.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/illnessManage/illAddCase", //疾病添加好转病例
    name: "illAddCase",
    component: () => import("../views/illAddCase.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/patientManage", //患者列表
    name: "patientManage",
    component: () => import("../views/patientManage.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/signUpPatManage", //患者列表
    name: "signUpPatManage",
    component: () => import("../views/signUpPatManage.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/medicalOrderManage", //诊单管理
    name: "MedicalOrderManage",
    component: () => import("../views/MedicalOrderManage.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/medicalOrderDetail", //诊单详情
    name: "MedicalOrderDetail",
    component: () => import("../views/MedicalOrderDetail.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/addSection", //社区管理
    name: "addSection",
    component: () => import("../views/addSection.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/addSection/addTwoSection", //社区管理
    name: "addTwoSection",
    component: () => import("../views/addTwoSection.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/noticeManage", //通知管理
    name: "NoticeManage",
    component: () => import("../views/NoticeManage.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/appPush", //app推送
    name: "APPPush",
    component: () => import("../views/APPPush.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/postManage", //帖子管理
    name: "postManage",
    component: () => import("../views/postManage.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/postManage/postDetail", //帖子详情加管理
    name: "postDetail",
    component: () => import("../views/postDetail.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/postManage/postRelease", //发布帖子
    name: "postRelease",
    component: () => import("../views/postRelease.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/reviewPost", //审核帖子
    name: "reviewPost",
    component: () => import("../views/reviewPost.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/patientDetail", //报名疾病管理
    name: "patientDetail",
    component: () => import("../views/patientDetail.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/illnessManage/illCycDetail", //疾病百科
    name: "illCycDetail",
    component: () => import("../views/illCycDetail.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/zzCycDetail", //症状百科
    name: "zzCycDetail",
    component: () => import("../views/zzCycDetail.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/userStatic", //注册用户数统计
    name: "userStatic",
    component: () => import("../views/userStatic.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/setDoctor/docLookPat", //注册用户数统计
    name: "DocLookPat",
    component: () => import("../views/DocLookPat.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/powerManage", //权限管理
    name: "PowerManage",
    component: () => import("../views/PowerManage.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/recommendToday", //今日推荐列表
    name: "RecommendToday",
    component: () => import("../views/RecommendToday.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/recommendToday/recTodayDetail", //今日推荐详情
    name: "RecTodayDetail",
    component: () => import("../views/RecTodayDetail.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  // {
  //   path:'/test',//权限管理
  //   name:'Test',
  //   component: ()=>import('../views/Test.vue'),
  //   meta:{
  //     title:'但行好事，莫问前程',
  //     keepAlive:false,
  //   }
  // },
  {
    path: "/talk", //权限管理
    name: "talk",
    component: () => import("../views/talk.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
      path: "talk",
    },
  },

  {
    path: "/talklookzd", //权限管理
    name: "talklookzd",
    component: () => import("../views/talklookzd.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: false,
      requireAuth: true,
    },
  },
  {
    path: "/leaveMsgManage", //患者留言管理
    name: "leaveMsgManage",
    component: () => import("../views/leaveMsgManage.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/leaveMsgManageDoc", //医生回复管理
    name: "leaveMsgManageDoc",
    component: () => import("../views/leaveMsgManageDoc.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/smallVideo", //小视频
    name: "smallVideo",
    component: () => import("../views/smallVideo.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/smallVideo/smallVideoDetial", //小视频
    name: "smallVideoDetial",
    component: () => import("../views/smallVideoDetial.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/liveManage", //直播管理
    name: "LiveManage",
    component: () => import("../views/LiveManage.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/liveManage/liveDetail", //直播详情页
    name: "LiveDetail",
    component: () => import("../views/LiveDetail.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/LiveDetaillook", //查看直播
    name: "LiveDetaillook",
    component: () => import("../views/LiveDetaillook.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/SearchingVideo", //视频管理
    name: "SearchingVideo",
    component: () => import("../views/SearchingVideo.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/SearchingVideoDetail", //视频管理
    name: "SearchingVideoDetail",
    component: () => import("../views/SearchingVideoDetail.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/changeIllOrder", //修改诊单
    name: "changeIllOrder",
    component: () => import("../views/changeIllOrder.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/searchservice", //查询用户所属客服
    name: "searchservice",
    component: () => import("../views/searchservice.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/setEmergency", //设置紧急
    name: "setEmergency",
    component: () => import("../views/setEmergency.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/XYOrderManage", //寻医诊单管理
    name: "XYOrderManage",
    component: () => import("../views/XYOrderManage.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/XYOrderDetail", //寻医诊单管理详情
    name: "XYOrderDetail",
    component: () => import("../views/XYOrderDetail.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/statusMsgManage", //状态说明管理
    name: "statusMsgManage",
    component: () => import("../views/statusMsgManage.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/changeservice", //修改服务人
    name: "changeservice",
    component: () => import("../views/changeservice.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/talk/lookvideodetail", //查看视频会话详情
    name: "lookvideodetail",
    component: () => import("../views/lookvideodetail.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: false,
    },
  },
  {
    path: "/userVideoList", //用户上传视频列表
    name: "userVideoList",
    component: () => import("../views/userVideoList.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/userVideoDetail", //用户上传视频列表详情页
    name: "userVideoDetail",
    component: () => import("../views/userVideoDetail.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/illAddCaseuser", //用户上传视频好转病例
    name: "illAddCaseuser",
    component: () => import("../views/illAddCaseuser.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/xyvideo", //用户上传视频寻医
    name: "xyvideo",
    component: () => import("../views/xyvideo.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/xyvideolist", //用户上传视频寻医列表
    name: "xyvideolist",
    component: () => import("../views/xyvideolist.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/ptUserupvideo", //用户上传视频好转病例列表
    name: "ptUserupvideo",
    component: () => import("../views/ptUserupvideo.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/setmoney", //设置诊费
    name: "setmoney",
    component: () => import("../views/setmoney.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/userArticleList", //用户上传列表
    name: "userArticleList",
    component: () => import("../views/userArticleList.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/userArticleDetail", //用户上传视频详情
    name: "userArticleDetail",
    component: () => import("../views/userArticleDetail.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/backvideo/videoLookReplay", //查看录制视频回放
    name: "videoLookReplay",
    component: () => import("../views/videoLookReplay.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: false,
      // requireAuth: true,
    },
  },
  {
    path: "/userUpload", //新版用户上传视频
    name: "userUpload",
    component: () => import("../views/userUpload.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/drugManage", //药品管理
    name: "drugManage",
    component: () => import("../views/drugManage.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/userUpload/useruploaddetail", //用户上传视频详情
    name: "useruploaddetail",
    component: () => import("../views/useruploaddetail.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/bindRelationship", //角色管理
    name: "bindRelationship",
    component: () => import("../views/bindRelationship.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/applyRefund", //退款管理毅飞//毅飞弃用20220329欧西思
    name: "applyRefund",
    component: () => import("../views/applyRefund.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/applyOXSRefund", //退款管理欧希思
    name: "applyOXSRefund",
    component: () => import("../views/applyOXSRefund.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/exportYFOrder", //毅飞所有订单//毅飞弃用20220329欧西思
    name: "exportYFOrder",
    component: () => import("../views/exportYFOrder.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/exportOXSOrder", //欧西思所有订单
    name: "exportOXSOrder",
    component: () => import("../views/exportOXSOrder.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/planManage", //方案管理
    name: "planManage",
    component: () => import("../views/planManage.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/MODetail", //方案查看详情
    name: "MODetail",
    component: () => import("../views/MODetail.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: false,
      requireAuth: true,
    },
  },
  {
    path: "/xyprewlist", //寻医总列表
    name: "xyprewlist",
    component: () => import("../views/xyprewlist.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: false,
      requireAuth: true,
    },
  },
  {
    path: "/xyprelogin", //寻医总列表登录
    name: "xyprelogin",
    component: () => import("../views/xyprelogin.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: false,
      requireAuth: true,
    },
  },
  {
    path: "/addUsersManually", //手动添加用户到数据库
    name: "addUsersManually",
    component: () => import("../views/addUsersManually.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/FKOrderManage", //反馈列表
    name: "FKOrderManage",
    component: () => import("../views/FKOrderManage.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/FKOrderDetail", //反馈详情
    name: "FKOrderDetail",
    component: () => import("../views/FKOrderDetail.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/FKplanmange", //反馈方案审核
    name: "FKplanmange",
    component: () => import("../views/FKplanmange.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/FKDetail", //方案查看详情
    name: "FKDetail",
    component: () => import("../views/FKDetail.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: false,
      requireAuth: true,
    },
  },
  {
    path: "/backvideo", //通过后台发起医患连线
    name: "backvideo",
    component: () => import("../views/backvideo.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/talklook", //大工号查看聊天权限
    name: "talklook",
    component: () => import("../views/talklook.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/orderReview", //订单审核
    name: "orderReview",
    component: () => import("../views/orderReview.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/reportlist", //直播-举报列表
    name: "reportlist",
    component: () => import("../views/reportlist.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/orderReviewtk", //处方订单列表
    name: "orderReviewtk",
    component: () => import("../views/orderReviewtk.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/queryTransferRecords", //查询转移记录
    name: "queryTransferRecords",
    component: () => import("../views/queryTransferRecords.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/distributionXYOrder", //分配寻医表给指定医生
    name: "distributionXYOrder",
    component: () => import("../views/distributionXYOrder.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/talkmonitor", //聊天监听
    name: "talkmonitor",
    component: () => import("../views/talkmonitor.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/LivePreview", //直播预告
    name: "LivePreview",
    component: () => import("../views/LivePreview.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/mallOrder", //商城订单
    name: "mallOrder",
    component: () => import("../views/mallOrder.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/timeoutNoContacted", //超时未联系
    name: "timeoutNoContacted",
    component: () => import("../views/timeoutNoContacted.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/diagnosisSheetMark", //诊单标记
    name: "diagnosisSheetMark",
    component: () => import("../views/diagnosisSheetMark.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/ys", //隐私协议
    name: "ys",
    component: () => import("../views/ys.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: false,
    },
  },
  {
    path: "/exportMallOrder", //导出商城订单
    name: "exportMallOrder",
    component: () => import("../views/exportMallOrder.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/bindingRelation", //绑定上下级关系
    name: "bindingRelation",
    component: () => import("../views/bindingRelation.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/auctionStatic", //拍卖统计
    name: "auctionStatic",
    component: () => import("../views/auctionStatic.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/own", //自留功能页面
    name: "own",
    component: () => import("../views/own.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
    },
  },
  {
    path: "/handleAbnormalOrder", //处理异常订单
    name: "handleAbnormalOrder",
    component: () => import("../views/handleAbnormalOrder.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/reportform1", //自己导出报表
    name: "reportform1",
    component: () => import("../views/reportform1.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/groupAuction", //拍卖监控
    name: "groupAuction",
    component: () => import("../views/groupAuction.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/selfBuiltPatient", //自建患者
    name: "selfBuiltPatient",
    component: () => import("../views/selfBuiltPatient.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/scienceArticle", //医生科普
    name: "scienceArticle",
    component: () => import("../views/scienceArticle.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/logincopy", //登录copy
    name: "logincopy",
    component: () => import("../views/logincopy.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: false,
    },
  },
  {
    path: "/illLibIndex", //首页热门疾病设置
    name: "illLibIndex",
    component: () => import("../views/illLibIndex.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/seniorityArticle", //资质文章
    name: "seniorityArticle",
    component: () => import("../views/seniorityArticle.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/userWelcomingsSpeech", //设置欢迎词
    name: "userWelcomingsSpeech",
    component: () => import("../views/userWelcomingsSpeech.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    path: "/patientManage/footprint", //30天浏览记录
    name: "footprint",
    component: () => import("../views/footprint.vue"),
    meta: {
      title: "但行好事，莫问前程",
      keepAlive: true,
      requireAuth: true,
    },
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  next();
});
export default router;
