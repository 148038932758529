<template>
  <div id="app">
    <div v-if="$route.meta.keepAlive" class="desktop">
      <el-container>
        <el-header v-if="$route.meta.path != 'talk'" id="header" style="height: 5vh">
          <div class="left-item">
            <div class="logo-container" style="cursor: pointer" @click="$router.push('/')">
              <img src="./assets/new1.png" alt="" style="width: 50px; margin: 0 27px" />
              <span>APP后台管理</span>
            </div>
          </div>
          <div class="right-item">
            <div class="user">欢迎您的登录:{{ username }}</div>
            <el-button type="info" class="logoutbtn" @click="logout">退出</el-button>
          </div>
        </el-header>
        <el-container>
          <el-aside v-if="hidemenu" class="el-menu-vertical-demo mainmenu" style="width: auto; position: relative; overflow: inherit">
            <el-menu background-color="#518fc9" text-color="#fff" class="el-menu-vertical-demo" active-text-color="#abd8fd" unique-opened style="border-right: none; overflow: hidden" :collapse="isCollapse" :collapse-transition="false">
              <!--58936c <el-radio-group v-model="isCollapse" class="shousuo">
                <el-radio-button :label="false"><i class="el-icon-s-unfold ftsz16"></i></el-radio-button>
                <el-radio-button :label="true"><i class="el-icon-s-fold ftsz16"></i></el-radio-button>
              </el-radio-group> -->
              <div v-model="isCollapse" class="shousuo">
                <p @click="menuss" class="shousuop">
                  <i :class="menustyle"></i>
                </p>
              </div>
              <el-submenu v-for="(item, index) in menus" :key="index" :index="(index + 1).toString()">
                <template #title>
                  <i :class="item.img"></i>
                  <span>{{ item.name }}</span>
                </template>
                <!-- 二级菜单 -->
                <el-menu-item @click="go(subitem)" v-for="(subitem, subindex) in item.children" :key="subindex" :index="index + 1 + '-' + (subindex + 1)">
                  <i class="el-icon-menu"></i>
                  <el-tooltip effect="dark" :content="subitem.name" placement="right">
                    <span style="display: inline-block; width: 85px">{{
                      subitem.name
                    }}</span>
                  </el-tooltip>
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </el-aside>
          <el-container>
            <el-main>
              <div class="main-broad">
                <router-view ref="child" v-if="isRouterAlive"></router-view>
              </div>
            </el-main>
          </el-container>
        </el-container>
      </el-container>
    </div>
    <div v-if="!$route.meta.keepAlive">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
var isoutline = false;
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      user: "",
      menus: [],
      username: "",
      hidemenu: true,
      isRouterAlive: true,
      isCollapse: false,
      menustyle: "el-icon-s-fold",
      connectionState: this.connection.connectionState,
    };
  },
  computed: {
    menu2() {
      return this.$store.state.newmenu;
    },
    username2() {
      return this.$store.state.username;
    },
  },
  watch: {
    menu2(n, d) {
      sessionStorage.setItem("menu", JSON.stringify(n));
      this.menus = n;
      if (
        this.menus &&
        this.menus.length > 0 &&
        this.menus[0].children[0].url == "talk"
      ) {
        this.hidemenu = false;
      }
    },
    username2(n, d) {
      this.username = n;
    },
    connectionState(n, d) {
      if (n == "Disconnected" || n == "Disconnecting" || n == "Reconnecting") {
        // if (sessionStorage.getItem("islogin") != null) {
        var _this = this;
        _this.$alert("断开链接，请重新连接", "警告", {
          confirmButtonText: "确定",
          callback: (action) => {
            _this.connection.stop();
            // _this.$router.replace("/");
            // localStorage.clear();
            // sessionStorage.clear();
            //链接断了之后重连
            _this.BeforeUnloadFn(1);
          },
        });
        //}
      }
    },
  },
  created() {},
  mounted() {
    var _this = this;
    setInterval(function () {
      _this.connectionState = _this.connection.connectionState;
      //   console.log(_this.connection.connectionState);
      //   if(_this.connection.connectionState === 'Disconnected'){
      //     _this.$alert("链接超时请重新登陆", "警告", {
      //             confirmButtonText: "确定",
      //             callback: (action) => {
      //               _this.connection.stop();
      //               _this.$router.replace("/");
      //             },
      //           });
      //   }
    }, 1000);
    // window.addEventListener("beforeunload", (e) => this.BeforeUnloadFn(e));
    this.BeforeUnloadFn(1);
    if (sessionStorage.getItem("menu") != "undefined") {
      this.menus = JSON.parse(sessionStorage.getItem("menu"));
      if (
        this.menus &&
        this.menus.length > 0 &&
        this.menus[0].children[0].url == "talk"
      ) {
        this.hidemenu = false;
      }
    }
    this.username = sessionStorage.getItem("name");
  },
  methods: {
    BeforeUnloadFn(e) {
      if (sessionStorage.getItem("islogin")) {
        this.connection = new signalR.HubConnectionBuilder()
          .withUrl(this.serveurl.serve + "/AdminChat")
          .build();
        // this.connection.start();
        let _this = this;
        this.connection
          .start()
          .then(function () {
            _this.connection.invoke("login", sessionStorage.getItem("name"));
          })
          .catch((error) => {
            _this.connection.invoke("login", sessionStorage.getItem("name"));
          });
        this.connection.on("logined", function (ip) {
          // alert(ip)
          _this.connection.stop();
          // _this.$router.replace("/");
          _this.axios
            .post(
              "/log/chat_login_log",
              _this.qs.stringify({
                data:
                  "【退出】 " +
                  sessionStorage.getItem("id") +
                  "  ---   " +
                  sessionStorage.getItem("name"),
              })
            )
            .then((res) => {});
          _this.$alert("你的账户在IP：" + ip + "上登录了", "警告", {
            confirmButtonText: "确定",
            callback: (action) => {
              isoutline = true;
              _this.connection.stop();
              localStorage.clear();
              sessionStorage.clear();
              _this.$router.replace("/");
            },
          });
        });
      }
    },
    go(item) {
      if (item.url == "talk") {
        this.$alert("只有客服有权限登入聊天", "警告", {
          confirmButtonText: "确定",
          callback: (action) => {},
        });
      } else {
        if (item.name == "视频管理") {
          this.$router.push({ path: "/" + item.url, query: { type: 1 } });
        } else if (item.name == "视频审核") {
          this.$router.push({ path: "/" + item.url, query: { type: 2 } });
        } else {
          this.$router.push("/" + item.url);
        }
        if (item.name == "竞得用户详情") {
          this.$router.push({
            path: "/" + item.url,
            query: { id: item.img, name: item.name },
          });
        }
        if (item.name == "用户推荐汇总") {
          this.$router.push({
            path: "/" + item.url,
            query: { id: item.img, name: item.name },
          });
        }
        if (item.name == "拍卖汇总统计") {
          this.$router.push({
            path: "/" + item.url,
            query: { id: item.img, name: item.name },
          });
        }
        if (item.name == "其他商品被推荐人订单") {
          this.$router.push({
            path: "/" + item.url,
            query: { id: item.img, name: item.name },
          });
        }
      }
    },
    logout() {
      this.axios
        .post(
          "/log/chat_login_log",
          this.qs.stringify({
            data:
              "【退出】 " +
              sessionStorage.getItem("id") +
              "  ---   " +
              sessionStorage.getItem("name"),
          })
        )
        .then((res) => {});
      localStorage.clear();
      sessionStorage.clear();
      this.$store.commit("getusername", "");
      this.connection.stop();
      if (this.$route.path == "/talk") {
        this.$refs.child.userlogout();
      } else {
        console.log("no");
      }
      this.$router.replace("/");
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    menuss() {
      this.isCollapse = !this.isCollapse;
      if (this.isCollapse) {
        this.menustyle = "el-icon-s-unfold";
      } else {
        this.menustyle = "el-icon-s-fold";
      }
    },
  },
};
</script>
<style>
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  min-height: 800px;
  font-size: 12px !important;
}

.desktop {
  margin: 0;
  padding: 0;
  background-color: rgba(240, 242, 245, 1);
}

.main-broad {
  width: 100%;
  background-color: #fff;
  padding: 15px;
  margin: 0;
  box-sizing: border-box;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.15) !important;
  border-radius: 3px;
}

.logout {
  padding: 0 20px;
  color: #fff;
}

.left-item {
  width: 50%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.right-item {
  width: 50%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}

#header {
  padding: 0;
  box-sizing: border-box;
  min-height: 55px;
  /* background-color: #2e76ee; f6f0ce 244a31*/
  background-color: #1e63b0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-image: none !important;
}

.logo-container {
  justify-self: flex-start;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}

.user {
  margin-right: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.user span {
  color: #fff;
  padding: 0 10px;
  font-size: 15px;
}

.logo-container span {
  font-size: 19px;
  font-family: "PingFang SC";
  color: #fff;
  /* color: #432e1d; */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* min-height: 800px; */
  min-height: 100vh;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.user {
  color: #fff;
  /* color: #432e1d; */
}

.el-header {
  align-items: center;
  color: #fff;
}

.el-aside {
  text-align: left;
  /* background-color: #354252; 58936c*/
  background-color: #518fc9;
  overflow: inherit;
  overflow: initial;
}
#app .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 170px;
}
.shousuo .el-radio-button__inner {
  padding: 10px;
}
.ftsz16 {
  font-size: 16px;
}
.mainmenu .el-submenu__title i {
  color: #fff;
}
.mainmenu .el-menu-item i {
  color: #fff;
}
.mainmenu .el-menu-item.is-active {
  color: #3b5d79 !important;
  background-color: #abd8fd !important;
}
.mainmenu .el-menu-item.is-active .el-icon-menu {
  color: #3b5d79 !important;
}
.mainmenu .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff;
  background-color: #58936c;
  border-color: #58936c;
  box-shadow: -1px 0 0 0 #58936c;
}
.mainmenu .el-radio-button__inner:hover {
  color: #58936c;
}
.el-menu--vertical .el-menu-item i {
  color: #fff;
}
.el-menu--vertical .is-active {
  color: rgb(67, 46, 29) !important;
  background-color: #f6f0ce !important;
}
.right-item .logoutbtn {
  color: #432e1d;
  background-color: #f6f0ce;
  border-color: #f6f0ce;
  margin-right: 10px;
}
.mainmenu .el-menu-item,
.el-submenu__title {
  height: 50px !important;
  line-height: 50px !important;
}
.shousuop {
  color: #fff;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  margin: 0;
}
.el-menu-item .item {
  background: none;
}
.el-table thead {
  font-size: 16px;
  color: #606266;
}
</style>
