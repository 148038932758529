import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    newmenu:'',
    username:'',
    useruploadpage:"",
    list:[],
    savelist:[],
  },
  mutations: {
    setmenu(state,new_menu){
      state.newmenu = new_menu;
    },
    getusername(state,val){
      state.username = val;
    },
    setuseruploadpage(state,val){
      state.useruploadpage = val;
    },
    setList(state, data){
      state.list = data;
    },
    setsaveList(state, data){
      state.savelist = data;
    }
  },
  actions: {
  },
  modules: {
  }
})
