import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";
import qs from "qs";
import VueQuillEditor from "vue-quill-editor";
import VueUeditorWrap from "vue-ueditor-wrap";
import serveurl from "./assets/serve.js";
import { Message } from "element-ui";

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueQuillEditor);
Vue.use(VueUeditorWrap);
Vue.component("vue-ueditor-wrap", VueUeditorWrap);

Vue.prototype.serveurl = serveurl;
Vue.prototype.qs = qs;
Vue.prototype.axios = axios;
axios.defaults.baseURL = "http://192.168.11.253:8080/index.php";
// axios.defaults.baseURL = 'http://112.250.105.14:10087/index.php';

var connection = new signalR.HubConnectionBuilder()
.withUrl(serveurl.serve+"/AdminChat")
.build();

Vue.prototype.connection = connection;

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  var arr = [];
  var arr1 = [];
  if (to.matched.some((record) => record.meta.requireAuth)) {
    // 判断该路由是否需要登录权限
    if (sessionStorage.id) {
      // 判断当前的id是否存在 ； 登录存入的id
      next();
    } else {
      next({
        path: "/",
        query: { redirect: to.fullPath }, // 将跳转的路由path作为参数，登录成功后跳转到该路由
      });
    }

    if (sessionStorage.right) {
      arr = JSON.parse(sessionStorage.right);
      for (let i = 0; i < arr.length; i++) {
        let b = arr[i].children;
        for (let m = 0; m < b.length; m++) {
          arr1.push(b[m]);
        }
      }
      //判断是否具有访问该菜单的权限
      if (to) {
        var isinright = inarr(to, arr1);
        if (isinright) {
          next();
        } else {
          Message({
            message: "没有权限进入此页面，请联系管理员",
            type: "warning",
          });
          next({
            path: from.path,
          });
        }
      }
    } else {
      next({
        path: "/",
        query: { redirect: to.fullPath }, // 将跳转的路由path作为参数，登录成功后跳转到该路由
      });
    }
  } else {
    next();
  }
  // next();
});
//判断去往的页面，是否在权限数组中存在
//存在则可以去，不存在不可以
function inarr(value, arr) {
  let a = [];
  a = value.path.split("/");
  let path = "";
  if (a.length > 2) {
    path = a[1];
  } else {
    path = a[1];
  }
  for (let y = 0; y < arr.length; y++) {
    if (path === arr[y].url) {
      return true;
    }
  }
  return false;
}
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
